import { Badge } from 'antd';
import { inject, observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import { PUBLIC_IMAGE_URL } from '../../utils';
import styles from './index.less';
import type { Shop } from './interface';

@inject('store')
@observer
export class ShopList extends Component<{ store?; }> {
  render(): ReactNode {
    const { chosenShopId, shopList, chooseShop, cooperationVendorNum } = this.props.store;
    return (
      <div
        className={`${styles.shopListWrapper} ${styles.bgf}`}
      >
        <div className={`${styles.shopListTitle} ${styles.flex} ${styles.fjcsBetween}`}>
          <div className={styles.title}>
            专享推款
          </div>
          <div
            className={styles.cooperationVendor}
            onClick={() => {
              window.open('/egenie-ts-vogue/cooperationStall/index');
            }}
          >
            合作档口
            <span className={styles.ml4}>
              {cooperationVendorNum}
            </span>
            <img
              className={styles.arrowIcon}
              src={`${PUBLIC_IMAGE_URL}rightArr.png`}
            />
          </div>
        </div>
        <div className={`${styles.bgf} ${styles.shopContent}`}>
          {
            shopList.map((el: Shop, index: number) => {
              const { vendorId, vendorName, unreadCount, summary } = el;
              return (
                <div
                  className={`${styles.shopItemWrapper} ${styles.flex} ${styles.faiCenter} ${chosenShopId === vendorId && styles.shopItemActive}`}
                  key={vendorId}
                  onClick={() => chooseShop(el)}
                >
                  {chosenShopId === vendorId && <div className={styles.indicator}/>}
                  {unreadCount > 0 && (
                    <Badge
                      className={styles.badge}
                      dot
                      size="small"
                    />
                  )}
                  <img
                    className={styles.shopImage}
                    src={index ? `${PUBLIC_IMAGE_URL}defaultShopImg.png` : `${PUBLIC_IMAGE_URL}summary.png`}
                  />
                  <div className={`${styles.flex} ${styles.fdColumn} ${styles.fjcCenter}`}>
                    <div className={styles.vendorName}>
                      {vendorName}
                    </div>
                    <div className={styles.shopDesc}>
                      {summary}
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }
}
