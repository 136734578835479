import { observer, Provider } from 'mobx-react';
import type { ReactElement } from 'react';
import React, { useEffect, useMemo } from 'react';
import { setLocationHref } from '../../utils';
import { Navigation } from '../components/navigation';
import { RightSider } from '../components/rightSider';
import { RightSiderStore } from '../components/rightSider/store';
import styles from './index.less';
import { ProductList } from './productList';
import { ShopList } from './shopList';
import { ExclusiveStore } from './store';

export default observer((): ReactElement => {
  const store = useMemo(() => {
    return new ExclusiveStore();
  }, []);

  useEffect(() => {
    store.getCooperationVendorNum();
    store.getRecommendNum();
    store.getShopList();
    store.getVendorLabelList();
    store.getDynamicList();
    setLocationHref();
  }, []);

  return (
    <Provider store={store}>
      <div className={styles.exclusive}>
        <Navigation className={styles.navigation}/>
        <div className={styles.exclusiveContent}>
          <ShopList/>
          <ProductList/>
        </div>
        <RightSider store={store.rightSiderStore}/>
      </div>
    </Provider>
  );
});
