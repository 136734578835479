import { Affix, Spin, Tabs } from 'antd';
import { inject, observer } from 'mobx-react';
import type { ReactNode } from 'react';
import React, { Component } from 'react';
import { BlankSegment, DynamicProductCard, Filter, PaginationCus, ProductCard, VendorRelated } from '../components';
import EmptyStatus from '../components/empty';
import styles from './index.less';
import type { GoodsVo, Style } from './interface';
import type { ExclusiveStore } from './store';

@inject('store')
@observer
export class ProductList extends Component<{ store?: ExclusiveStore; }> {
  render(): ReactNode {
    const {
      loading,
      showMode,
      chosenShopId,
      filter,
      dynamicList,
      styleList,
      totalCount,
      totalPageCount,
      paginationStore,
      queryParams,
      containerRef,
      onShowModeChange,
      setRef,
      rightSiderStore,
    } = this.props.store;
    const tabItems = [
      {
        key: '1',
        label: '按动态展示',
      },
      {
        key: '2',
        label: '按款式库展示',
      },
    ];
    return (
      <div className={`${styles.bgf} ${styles.spin}`}>
        <Tabs
          activeKey={String(showMode)}
          className={`tab ${styles.tabs}`}
          defaultActiveKey="1"
          items={tabItems}
          onChange={onShowModeChange}
        />
        <div
          className={styles.productWrapper}
          ref={(ref) => setRef(ref)}
        >
          <Spin spinning={loading}>
            {chosenShopId === 0 && <Filter store={filter}/>}
            {
              loading ? <BlankSegment/> : (
                <div className={styles.productListContent}>
                  {showMode === 1 && (dynamicList.length ? dynamicList.map((item) => {
                    const {
                      dynamicId,
                      vendorId,
                      vendorName,
                      createdAt,
                      recommendDesc,
                      goodsVoList,
                      discountPer,
                    } = item;
                    return (
                      <VendorRelated

                        createdAt={createdAt}
                        discountPercent={discountPer}
                        key={dynamicId}
                        listContent={<StallGoodsInfo goodsList={goodsVoList}/>}
                        recommendDesc={recommendDesc}
                        vendorId={vendorId}
                        vendorName={vendorName}
                        width={920}
                      />
                    );
                  })
                    : (
                      <div className={styles.empty}>
                        <EmptyStatus/>
                      </div>
                    ))}

                  {showMode === 2 && (styleList.length ? (
                    <StyleInfo
                      list={styleList}
                      rightSiderStore={rightSiderStore}
                    />
                  ) : (
                    <div className={styles.empty}>
                      <EmptyStatus/>
                    </div>
                  ))}
                  <PaginationCus
                    customClassName={styles.pagination}
                    pageSize={queryParams.pageSize}
                    store={paginationStore}
                    totalCount={totalCount}
                    totalPageCount={totalPageCount}
                  />
                </div>
              )
            }
          </Spin>
        </div>
      </div>
    );
  }
}

const StallGoodsInfo = observer((props: { goodsList: GoodsVo[]; }) => {
  const { goodsList } = props;
  if (goodsList.length === 0) {
    return null;
  } else {
    return (
      <div className={styles.goodsWrapper}>
        {
          goodsList.map((item: GoodsVo) => {
            const {
              activityPrice,
              discountPrice,
              goodsId,
              mainPicUrl,
              visibleType,
              price,
            } = item;
            return (
              <DynamicProductCard
                activityPrice={activityPrice}
                className={styles.productCard}
                discountPrice={discountPrice}
                goodsId={goodsId}
                key={goodsId}
                mainPicUrl={mainPicUrl}
                overflow
                price={price}
                visibleType={visibleType}
              />
            );
          })
        }
      </div>
    );
  }
});

const StyleInfo = observer((props: { list: Style[]; rightSiderStore; }) => {
  const {
    list,
    rightSiderStore,
  } = props;
  if (list.length === 0) {
    return null;
  } else {
    return (
      <div className={styles.goodsWrapper}>
        {
          list.map((item: Style) => {
            const {
              goodsId,
              activityPrice,
              discountPrice,
              goodsName,
              mainPic,
              picGroupNum,
              returnRate,
              returnable,
              wholePrice,
              doesAddStyle,
              sellingPointTag,
              virtualSaleNum,
              virtualSelectNum,
              liveGoodsVo,
            } = item;
            return (
              <ProductCard
                activityPrice={activityPrice}
                callback={() => {
                  rightSiderStore.getMyStyleWaitNum();
                }}
                className={styles.productCard}
                discountPrice={discountPrice}
                doesAddStyle={doesAddStyle}
                goodsId={goodsId}
                goodsName={goodsName}
                key={goodsId}
                liveGoodsVo={liveGoodsVo}
                mainPic={mainPic}
                overflow
                picGroupNum={picGroupNum}
                returnRate={returnRate}
                returnable={returnable}
                sellingPointTag={sellingPointTag}
                virtualSaleNum={virtualSaleNum}
                virtualSelectNum={virtualSelectNum}
                wholePrice={wholePrice}
              />
            );
          })
        }
      </div>
    );
  }
});
