import type { BaseData, PaginationData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { api } from '../../utils';
import { FilterStore } from '../components/filter/store';
import PaginationStore from '../components/pagination/store';
import { RightSiderStore } from '../components/rightSider/store';
import type { Dynamic, Shop, Style, VendorLabel } from './interface';

export class ExclusiveStore {
  @observable public cooperationVendorNum = 0; // 合作档口数

  @observable public loading = false; // 页面加载状态

  @observable public chosenShopId = 0; // 选择的档口ID 0为汇总

  @observable public showMode = 1; // 展示方式 1按动态展示 2按款式展示

  @observable public shopList: Shop[] = [
    {
      vendorId: 0,
      vendorName: '汇总',
      unreadCount: 0,
      summary: '近7天，共推荐269款',
      tags: [],
      discountPer: '',
    },
  ];

  @observable public dynamicList = []; // 动态列表

  @observable public styleList = []; // 款式列表

  @observable public queryParams = {
    page: 1,
    pageSize: 10,
    sidx: '',
    sord: '',
    tagId: '',
  };

  @observable public totalCount = 0; // 总数据条数

  @observable public totalPageCount = 0; // 总页数

  public paginationStore = new PaginationStore({ parent: this });

  public rightSiderStore = new RightSiderStore({ parent: this });

  public containerRef = null;

  public filter = new FilterStore({
    filterItems: [
      {
        type: 'select',
        field: 'tagId',
        placeholder: '全部合作档口',
        allowClear: true,
      },
    ],
    handleSearch: () => {
      const { tagId } = this.filter.params;
      this.queryParams.tagId = tagId;
      this.onSearch();
    },
  });

  public setRef = (containerRef) => {
    this.containerRef = containerRef;
  };

  @action public chooseShop = (row: Shop): void => {
    this.chosenShopId = row.vendorId;
    const index = this.shopList.findIndex((item) => item.vendorId === row.vendorId);
    if (index > -1) {
      this.shopList[index].unreadCount = 0;
    }
    this.showMode = 1;
    this.onSearch();
  };

  @action public onShowModeChange = (key: string): void => {
    this.showMode = Number(key);
    this.filter.updateFilterParams({ tagId: undefined });
    this.paginationStore.resetPage();
    this.queryParams.tagId = ''; // 清空筛选条件和分页
    this.queryParams.pageSize = Number(key) === 1 ? 10 : 40;
    this.onSearch();
  };

  // 切换档口或者展示方式后的查询
  @action public onSearch = (page = 1): void => {
    if (this.showMode === 1) {
      this.queryParams.page = page;
      this.queryParams.pageSize = 10;
      this.getDynamicList();
    } else {
      this.queryParams.page = page;
      this.queryParams.pageSize = 40;
      this.getStyleList();
    }
  };

  // 查询合作档口数
  @action public getCooperationVendorNum = async(): Promise<void> => {
    const res = await request<BaseData<number>>({ url: api.getCooperationVendorNum });
    this.cooperationVendorNum = res.data;
  };

  // 查询近7天推款数
  @action public getRecommendNum = async(): Promise<void> => {
    const res = await request<BaseData<number>>({ url: api.getRecommendNum });
    this.shopList[0].summary = `近7天，共推荐${res.data}款`;
  };

  // 查询推款档口列表
  @action public getShopList = async(): Promise<void> => {
    const res = await request<BaseData<Shop[]>>({ url: api.getRecommendVendorList });
    this.shopList = this.shopList.concat(res.data);
  };

  // 查询所有标签
  @action public getVendorLabelList = async(): Promise<void> => {
    const res = await request<BaseData<VendorLabel[]>>({ url: api.getVendorLabel });
    const list = res.data.map((el) => {
      return {
        value: el.id,
        label: el.tagName,
      };
    });
    this.filter.updateFilterItems({ tagId: list });
  };

  // 回到顶部
  @observable public backTop = (): void => {
    this.containerRef.scrollTop = 0;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };

  // 分页
  public onPageChange = (page: number, pageSize: number): void => {
    this.onSearch(page);
  };

  // 切换页数
  public onShowSizeChange = (page: number, pageSize: number): void => {
    this.onSearch(page);
  };

  // 查询动态列表
  @action public getDynamicList = (): void => {
    const postData = {
      ...this.queryParams,
      vendorId: this.chosenShopId,
    };
    this.loading = true;
    request<PaginationData<Dynamic[]>>({
      url: api.getDynamicList,
      method: 'POST',
      data: postData,
    }).then((res) => {
      this.dynamicList = res.data.list;
      this.totalCount = res.data.totalCount;
      this.totalPageCount = res.data.totalPageCount;
    })
      .catch((err) => {
        this.dynamicList = [];
        this.totalCount = 0;
        this.totalPageCount = 0;
      })
      .finally(() => {
        this.loading = false;
        this.backTop();
      });
  };

  // 查询款式列表
  @action public getStyleList = (): void => {
    const postData = {
      ...this.queryParams,
      vendorId: this.chosenShopId,
    };
    this.loading = true;
    request<PaginationData<Style[]>>({
      url: api.getRecommendStyleList,
      method: 'POST',
      data: postData,
    }).then((res) => {
      this.styleList = res.data.list;
      this.totalCount = res.data.totalCount;
      this.totalPageCount = res.data.totalPageCount;
    })
      .catch((err) => {
        this.styleList = [];
        this.totalCount = 0;
        this.totalPageCount = 0;
      })
      .finally(() => {
        this.loading = false;
        this.backTop();
      });
  };
}
